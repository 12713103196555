<template>
    <div>
      <div v-if="activeTab === 'list'">
        <div class="row align-items-center mb-3">
          <div class="col-md-4 d-flex">
            <label for="" class="d-flex mt-2 font-weight-bold">
              Start date
            </label>
            <input
              type="text"
              v-model="startDate"
              disabled
              class="form-control custom-input-width ml-2 pl-2"
            />
          </div>
  
          <div class="col-md-4 d-flex">
            <label for="" class="d-flex mt-2 font-weight-bold"> End date </label>
            <input
              type="text"
              v-model="endDate"
              disabled
              class="form-control custom-input-width ml-2 pl-2"
            />
          </div>
        </div>
        <div class="row align-items-center mb-5 mt-5">
          <div class="col-md-3"></div>
          <div class="col-md-2">
            <button type="button" class="btn btn-sm btn-info" @click="getPenwiseDailyFeedData()">
              <md-icon class="text-white">list</md-icon> Generate Report
            </button>
          </div>
  
          <!-- <div class="col-md-2">
            <button type="button" 
             class="btn btn-sm btn-primary" 
            :disabled="!reportGenerated"
            @click="exportData()"
            >
              <md-icon class="text-white">download</md-icon>Export Data
            </button>
          </div> -->
  
          <div class="col-md-2">
            <button type="button" 
            class="btn btn-sm btn-success"
            :disabled="!penwiseFeedData.length > 0"
            @click="downloadPDF"
            >
              <md-icon class="text-white">download</md-icon> Download PDF
            </button>
          </div>
  
        </div>
  
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">PENWISE DAILY FEED CONSUMPTION</h4>
            </md-card-header>
            <md-card-content>
              <md-table class="custom-table" v-if="((!load && !pens.length) || (!load && !penwiseFeedData.length)) && !reportGenerated">
                  <tr>
                      <td :colspan="6">Please Generate Report</td>
                  </tr>
              </md-table>
              <loading-bar v-else-if="load"></loading-bar>
            </md-card-content>
  
            <md-card-content>
              <md-table v-if="penwiseFeedData.length > 0 && pens.length > 0" :table-header-color="tableHeaderColor" class="custom-table" >
                <tr>
                  <th style="border: 1px solid black;" :rowspan="2">Days</th>
                  <th v-for="(group, index) in trialGroupHeaders" style="border: 1px solid black;"
                      :key="'group-' + index" 
                      :colspan="group.colspan">
                    T{{ group.trial_group_id }}
                  </th>
                </tr>
                <tr>
                  <th style="border: 1px solid black;" v-for="(pen, index) in pens" :key="'pen-' + index">
                    P{{ pen.pen_id }}
                  </th>
                </tr>
                    <tr v-for="(penData, index) in penwiseFeedData" :key="index">
                        <th>{{ penData.age_in_days_week }}</th>
                        <td v-for="(pen, penIndex) in pens" :key="penIndex">
                            {{ penData.feed_data.find(feed => feed.pen_id == pen.pen_id)?.feed_consumed_per_bird || '-' }}
                        </td>
                    </tr>
              </md-table>
              <md-table class="custom-table" v-else-if="reportGenerated && (!penwiseFeedData.length > 0 || !pens.length > 0)">
                <tr>
                    <td :colspan="6">No records found</td>
                </tr>
              </md-table>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import LoadingBar from "../../components/LoadingBar.vue";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import autoTable from "jspdf-autotable";
  import * as XLSX from "xlsx";
  
  export default {
    components: { LoadingBar },
    name: "PenwiseDailyFeedConsumption",
    props: {
      tableHeaderColor: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
          penwiseFeedData: [], 
          reportGenerated: false,
          pens: [], 
          activeTab: "list", // Default active tab
          expInfoId: "",
          trialNo: "",
          startDate: "",
          endDate: "",
          load: false,
      };
    },
    watch: {
      // Watch for changes in the selected trial's id
      '$store.getters.getSelectedTrial.id': {
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            // Update related data when id changes
            this.expInfoId = newVal;
            this.startDate = this.$store.getters.getSelectedTrial.start_date;
            this.endDate = this.$store.getters.getSelectedTrial.end_date;
            this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
            // Call the filterData method whenever the trial id changes
            this.filterData();
          }
        },
        immediate: true // Call handler immediately after the component is mounted
      }
    },
  
  
    mounted() {
      this.expInfoId = this.$store.getters.getSelectedTrial.id;
      this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
      this.startDate = this.$store.getters.getSelectedTrial.start_date;
      this.endDate = this.$store.getters.getSelectedTrial.end_date;
    },
    computed: {
      trialGroupHeaders() {
        // Group pens by trial_group_id and calculate spans
        const grouped = this.pens.reduce((acc, pen) => {
          const group = acc.find((g) => g.trial_group_id === pen.trial_group_id);
          if (group) {
            group.colspan += 1;
          } else {
            acc.push({ trial_group_id: pen.trial_group_id, colspan: 1 });
          }
          return acc;
        }, []);

        return grouped;
      },
    },
    // watch: {
    //   trialNoDrp: function (value) {
    //     this.getTrialNoList();
    //   },
    // },
  
      methods: {
          isActive(tab) {
          return this.activeTab === tab;
          },
          setActiveTab(tab) {
          this.activeTab = tab;
          },
  
          filterData(){
            this.reportGenerated = false;
              this.penwiseFeedData = [];
              this.pens = [];
          },
          // Check if this is the first occurrence of a trial group
          isFirstInGroup(trialGroupId, index) {
              return index === 0 || this.penwiseFeedData[index - 1].trial_group_id !== trialGroupId;
          },
          // Get the number of rows for a trial group
          getRowSpan(trialGroupId) {
              return this.penwiseFeedData.filter(pen => pen.trial_group_id === trialGroupId).length;
          },
          async getPenwiseDailyFeedData(){
            this.reportGenerated = false;
              this.load = true;
              this.penwiseFeedData = [];
              try {
                  const response = await axios.get(`/indous-research/reports/penwise-daily-feed-data`);
                  this.penwiseFeedData = response.data.data;
                  this.pens = response.data.pens;
                  this.reportGenerated = true;
                  this.load = false;
              } catch (error) {
                  this.load = false;
              }
          },
  
          // exportData() {
          //   axios
          //       .post(
          //       "/download-mortal-birds-excel-report",
          //       {
          //           reportData: this.mortalBirdsReportList,
          //       },
          //       {
          //           responseType: "blob",
          //           headers: {
          //           "Content-Type": "application/json",
          //           },
          //       }
          //       )
          //       .then((response) => {
          //       const url = window.URL.createObjectURL(
          //           new Blob([response.data], {
          //           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          //           })
          //       );
          //       const link = document.createElement("a");
          //       link.href = url;
          //       link.setAttribute("download", "MortalBirdsReport.xlsx");
          //       document.body.appendChild(link);
          //       link.click();
          //       document.body.removeChild(link);
          //       })
          //       .catch((error) => {
          //       // console.error("There was an error generating the report:", error);
          //       });
          // },
  
          // formatColumnHeadings(data) {
          //     const headers = data[0].map(header => header); // Format headers
          //     const formattedData = [headers, ...data.slice(1)]; // Combine formatted headers with data
          //     return formattedData;
          // },
  
      
          // calculateColumnWidths(data) {
          //     const columnWidths = data[0].map((header, colIndex) => {
          //         let maxLength = header.length;
          //         data.slice(1).forEach(row => {
          //             const cellValue = row[colIndex] ? row[colIndex].toString() : '';
          //             maxLength = Math.max(maxLength, cellValue.length);
          //         });
          //         return { wch: maxLength };
          //     });
          
          //     return columnWidths;
          // },
  
          downloadPDF() {
            const doc = new jsPDF('landscape');
            doc.setFontSize(20);

            // Title and basic details
            const title = "Penwise Daily Feed Consumption";
            const pageWidth = doc.internal.pageSize.width;
            const textWidth = doc.getTextWidth(title);
            const xPosition = (pageWidth - textWidth) / 2;
            doc.text(title, xPosition, 13);

            const trialNo = `Trial No: ${this.trialNo}`;
            const startDate = `Start Date: ${this.startDate}`;
            const endDate = `End Date: ${this.endDate}`;
            doc.setFontSize(10);
            doc.setFont("Helvetica", "bold");
            doc.text(trialNo, 10, 20);
            doc.text(startDate, pageWidth - 50, 20);
            doc.text(endDate, pageWidth - 50, 25);

            // Step 1: Prepare the headers
            const firstHeader = [{ content: "Days", rowSpan: 2 }];
            const secondHeader = [];

            this.trialGroupHeaders.forEach(group => {
                firstHeader.push({ content: `T${group.trial_group_id}`, colSpan: group.colspan, styles: { halign: "center" } });
                this.pens.forEach(() => {
                    secondHeader.push({ content: "" }); // Empty cell for pen names
                });
            });

            const penHeader = this.pens.map(pen => `P${pen.pen_id}`);
            const headers = [
                [...firstHeader], // First header row
                [...penHeader],  // Second header row
            ];

            // Step 2: Prepare the body data
            const body = this.penwiseFeedData.map(penData => {
                const row = [];
                row.push(penData.age_in_days_week); // Add the 'Days' column

                this.pens.forEach(pen => {
                    const feedData = penData.feed_data.find(feed => feed.pen_id == pen.pen_id);
                    row.push(feedData ? feedData.feed_consumed_per_bird : "-");
                });

                return row;
            });

            // Step 3: Generate the PDF with jsPDF-AutoTable
            doc.autoTable({
                head: headers,
                body: body,
                theme: "grid",
                startY: 30, // Position below the title
                margin: { left: 5, right: 5 },
                styles: {
                    halign: "center", // Align all columns to the center
                    valign: "middle",
                },
                headStyles: {
                    fillColor: [41, 128, 185],
                    fontSize: 9,
                },
                bodyStyles: {
                    fontSize: 8,
                },
                columnStyles: {
                    0: { halign: "left" },
                },
            });

            doc.save("Penwise_Daily_Feed_Consumption.pdf");
        }

      } 
  };
  </script>
    
    <style scoped>
  .custom-md-field {
    width: 190px;
  }
  
  .custom-md-input {
    width: 50px;
    font-size: 14px;
  }
  
  .row {
    --ct-gutter-x: 1.5rem;
    --ct-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--ct-gutter-y));
    margin-right: calc(-0.5 * var(--ct-gutter-x));
    margin-left: calc(-0.5 * var (--ct-gutter-x));
  }
  
  .tab-content {
    padding: 20px 0 0 0;
    border: none;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #71b6f9 !important;
  }
  
  .align-items-center {
    align-items: center !important;
    padding: 0px 65px 0px 20px;
  }
  
  .mb-3 {
    margin-bottom: 1.5rem !important;
  }
  
  .col {
    flex: 1 0 0%;
  }
  
  .mt-0 {
    margin-top: 0 !important;
  }
  
  .header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
  }
  
  .card-box {
    background-color: #fff;
    padding: 1.2rem 0rem 0.2rem 0rem;
    -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    margin-bottom: 1px;
    border-radius: 0.25rem;
  }
  
  .col-auto {
    flex: 0 0 auto;
    width: auto;
  }
  
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input-group {
    display: inline;
  }
  
  button[type="submit"],
  button[type="button"].cancel-button {
    cursor: pointer;
  }
  
  #buttons {
    display: flex;
    flex-direction: row;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: rgb(113, 182, 249);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
  
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  .create-field {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .all-buttons {
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
  }
  
  .removePadding{
      padding: 0;
  }
  
  /* .addPadding{
      padding-right: 30px;
  } */
  </style>