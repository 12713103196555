import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import Usermanagement from "@/pages/Usermanagement.vue";
import LoginView from "@/pages/Auth/LoginView.vue";
import CorrectedFeedFormula from "@/pages/CorrectedFeedFormula.vue";
import FeedFormula from "@/pages/FeedFormula.vue";
import Experimentinfo from "@/pages/ExperimentInfo.vue";
import DataScreenEntry from "@/pages/DataScreenEntry.vue";
import BodyWeight from "@/pages/BodyWeight.vue";
import BaseFeedFormula from "@/pages/BaseFeedFormula.vue";
import TrialGroup from "@/pages/Master/TrialGroup.vue";
import BatchMaster from "@/pages/Master/BatchMaster.vue";
import FeedIngredient from "@/pages/Master/FeedIngredient.vue";
import TareWeightMaster from "@/pages/Master/TareWeightMaster.vue";
import FeederDrumMaster from "@/pages/Master/FeederDrumMaster.vue";
import CrateMaster from "@/pages/Master/CrateMaster.vue";
import Feeder from "@/pages/Master/Feeder.vue";
import Drinker from "@/pages/Master/Drinker.vue";
import GroupwiseWeight from "@/pages/Reports/GroupwiseWeight.vue";
import BirdRatio from "@/pages/Reports/BirdRatio.vue";
import PenwiseWeight from "@/pages/Reports/PenwiseWeight.vue";
import WeeklyReport from "../pages/Reports/WeeklyReport.vue";
import Weight5thWeek from "../pages/Reports/Weight5thWeek.vue";
import MortalDetails from "../pages/Reports/MortalDetails.vue";
import AllocationGroupwiseTare from "../pages/AllocationGroupwiseTare.vue";
import TrialSchedulePlan from "../pages/TrialSchedulePlan.vue";
import ComparativeStudyCertificate from "../pages/Documents/Certificate.vue";
import BoilerNutritionTrialCertificate from "../pages/Documents/ResultSubmission.vue";
import IndousResearchReport from "../pages/Documents/ResearchTeam.vue";
import FinalReport from "../pages/Documents/FinalReport.vue";
import store from "../store/store";
import FcrSummary from "../components/FcrSummary.vue";
import GroupwiseMortalDetails from "../pages/Reports/GroupwiseMortalDetails.vue";
import PenwiseDailyFeedData from "../pages/Reports/PenwiseDailyFeedData.vue";

Vue.use(VueRouter);

const loadComponent = (path) => {
  return () => import(`../pages/${path}`);
};

async function getRoutes() {
  const subMenus = store.getters.getSubMenus || [];
  return subMenus.map((route) => ({
    name: route.name,
    path: `/${route.slug}`,
    component: loadComponent(route.view_path),
  }));
}

export async function createRouter() {
  const routes = [
    {
      path: "/login",
      name: "Login",
      component: LoginView,
    },
    {
      path: "/",
      component: DashboardLayout,
      name:"Layout",
      redirect: "/login",
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "group-wise-tare-allocation",
          name: "Allocation of Groupwise Tare wt",
          component: AllocationGroupwiseTare,
        },
        {
          path: "certificate",
          name: "Certificate",
          component: ComparativeStudyCertificate,
        },
        {
          path: "result-submission",
          name: "Result Submission",
          component: BoilerNutritionTrialCertificate,
        },
        {
          path: "research-team",
          name: "Research team",
          component: IndousResearchReport,
        },
        {
          path: "final-report",
          name: "Final Report",
          component: FinalReport,
        },
        {
          path: "trial-group-master",
          name: "Trial Group master",
          component: TrialGroup,
        },
        {
          path: "batch-master",
          name: "Batch Master",
          component: BatchMaster,
        },
        {
          path: "feed-ingredient",
          name: "Feed Ingredient master",
          component: FeedIngredient,
        },
        {
          path: "tare-weight-master",
          name: "Tare Weight Master",
          component: TareWeightMaster,
        },
        {
          path: "feederdrummaster",
          name: "Feeder Drum Master",
          component: FeederDrumMaster,
        },
        {
          path: "feeder",
          name: "Feeder",
          component: Feeder,
        },
        {
          path: "drinker",
          name: "Drinker",
          component: Drinker,
        },
        {
          path: "cratemaster",
          name: "Crate Master",
          component: CrateMaster,
        },
        {
          path: "groupwise-weight",
          name: "Groupwise Male/Female Bird's Body Weight at 5th Week",
          component: GroupwiseWeight,
        },
        {
          path: "bird-ratio",
          name: "Male/Female Bird Ratio As Equal Numbers",
          component: BirdRatio,
        },
          {
          path: "mortal-details",
          name: "Mortal and Leg weak birds Details",
          component: MortalDetails,
        },
        {
          path: "groupwise-mortal-details",
          name: "Groupwise Mortality Details",
          component: GroupwiseMortalDetails,
        },
        {
          path: "penwise-daily-feed-consumption",
          name: "Penwise Daily Feed Consumption",
          component: PenwiseDailyFeedData,
        },
        // {
        //   path: "penwise-weekly-report",
        //   name: "Penwise Male/Female Body Weight on 5th Week - report",
        //   component: PenwiseWeight,
        // },
        {
          path: "penwise-weekly-report",
          name: "Weekly Penwise Report",
          component: WeeklyReport,
        },
        {
          path: "final-summary-report",
          name: "Final Report",
          component: FcrSummary,
        },
        {
          path: "weight-5thweek",
          name: "separate male and female birds weight at 5th week - report",
          component: Weight5thWeek,
        },
        {
          path: "user-management",
          name: "Usermanagement",
          component: Usermanagement,
        },
        {
          path: "trial-schedule-plan",
          name: "Pre-Trial/Trial - Day Wise Activity Plan",
          component: TrialSchedulePlan,
        },
        {
          path: "experiment-info",
          name: "Experiment Info",
          component: Experimentinfo,
        },
        {
          path: "corrected-feed-formula",
          name: "Corrected Feed Formula",
          component: CorrectedFeedFormula,
        },
        {
          path: "feed-formula",
          name: "Feed Formula",
          component: FeedFormula,
        },
        {
          path: "day-weekwise-entry",
          name: "Day/Weekwise Entry",
          component: DataScreenEntry,
        },
        {
          path: "Bodyweightview",
          name: "Results",
          component: BodyWeight,
        },
        {
          path: "premix-formula",
          name: "Premix Formula",
          component: BaseFeedFormula,
        },
        {
          path: "user",
          name: "User Profile",
          component: UserProfile,
        },
        {
          path: "table",
          name: "Table List",
          component: TableList,
        },
        {
          path: "typography",
          name: "Typography",
          component: Typography,
        },
        {
          path: "icons",
          name: "Icons",
          component: Icons,
        },
        {
          path: "maps",
          name: "Maps",
          meta: {
            hideFooter: true,
          },
          component: Maps,
        },
        {
          path: "notifications",
          name: "Notifications",
          component: Notifications,
        },
        {
          path: "upgrade",
          name: "Upgrade to PRO",
          component: UpgradeToPRO,
        },
      ],// Initially empty, will be populated dynamically
    },
  ];

  const router = new VueRouter({
    routes,
    linkExactActiveClass: "nav-item active",
  });

  // store.watch(
  //   () => store.getters.getSubMenus,
  //   async (newSubMenus) => {
  //     if (newSubMenus && newSubMenus.length > 0) {
  //       const dynamicRoutes = await getRoutes();
  //       dynamicRoutes.forEach((route) => {
  //         router.addRoute("Layout", route);
  //       });
       
  //     }
  //   },
  //   { immediate: true }
  // );

  return router;
}
