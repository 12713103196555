<template>
  <div>
    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold">
            Start date
          </label>
          <input
            type="text"
            v-model="startDate"
            disabled
            class="form-control custom-input-width ml-2 pl-2"
          />
        </div>

        <div class="col-md-4 d-flex">
          <label for="" class="d-flex mt-2 font-weight-bold"> End date </label>
          <input
            type="text"
            v-model="endDate"
            disabled
            class="form-control custom-input-width ml-2 pl-2"
          />
        </div>
      </div>
      <div class="row align-items-center mb-5 mt-5">
        <div class="col-md-3"></div>
        <div class="col-md-2">
          <button type="button" class="btn btn-sm btn-info" @click="getGroupwiseMortalData()">
            <md-icon class="text-white">list</md-icon> Generate Report
          </button>
        </div>

        <!-- <div class="col-md-2">
          <button type="button" 
           class="btn btn-sm btn-primary" 
          :disabled="!reportGenerated"
          @click="exportData()"
          >
            <md-icon class="text-white">download</md-icon>Export Data
          </button>
        </div> -->

        <div class="col-md-2">
          <button type="button" 
          class="btn btn-sm btn-success"
          :disabled="!mortalityData.length > 0 || totalMortalityData == null"
          @click="downloadPDF"
          >
            <md-icon class="text-white">download</md-icon> Download PDF
          </button>
        </div>

      </div>

      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
        <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">GROUPWISE MORTAL BIRD DETAILS</h4>
          </md-card-header>
          <md-card-content>
            <md-table class="custom-table" v-if="!load && !mortalityData.length">
                <tr>
                    <td :colspan="6">Please Generate Report</td>
                </tr>
            </md-table>
            <loading-bar v-else-if="load"></loading-bar>
          </md-card-content>

          <md-card-content v-for="data in mortalityData" :key="data.trial_group_id">
            <md-table :table-header-color="tableHeaderColor" class="custom-table" style="border: 1px solid black">
                <tr style="border: 0.5px solid black"></tr>
                <tr>
                    <th style="border: 0.5px solid black"></th>
                    <th style="border: 0.5px solid black" :colspan="data.penwise_mortality_count.length">{{ 'T' + data.trial_group_id }}</th>
                </tr>

                <tr>
                    <th style="border: 0.5px solid black">Pens</th>
                    <td style="border: 0.5px solid black" v-for="penData in data.penwise_mortality_count" :key="penData.pen_id">{{ penData.pen }}</td>
                </tr>

                <tr>
                    <th style="border: 0.5px solid black">Mortality</th>
                    <td style="border: 0.5px solid black" v-for="penData in data.penwise_mortality_count" :key="penData.pen_id">{{ penData.count }}</td>
                </tr>

                <tr>
                    <th style="border: 0.5px solid black">Groupwise Birds</th>
                    <td style="border: 0.5px solid black" :colspan="data.penwise_mortality_count.length">{{ data.total_alloted_birds }}</td>
                </tr>

                <tr>
                    <th style="border: 0.5px solid black">Mortality Birds</th>
                    <td style="border: 0.5px solid black" :colspan="data.penwise_mortality_count.length">{{ data.total_mortality_count }}</td>
                </tr>

                <tr>
                    <th style="border: 0.5px solid black">Live Birds</th>
                    <td style="border: 0.5px solid black" :colspan="data.penwise_mortality_count.length">{{ data.live_birds_remaining }}</td>
                </tr>

                <tr>
                    <th style="border: 0.5px solid black">Mortality Birds Weight</th>
                    <td style="border: 0.5px solid black" :colspan="data.penwise_mortality_count.length">{{ data.total_mortality_weight }}</td>
                </tr>

                <tr>
                    <th style="border: 0.5px solid black">Mortality %</th>
                    <td style="border: 0.5px solid black" :colspan="data.penwise_mortality_count.length">{{ data.mortality_percentage }}%</td>
                </tr>
                
            </md-table>
          </md-card-content>
          
          <md-card-content v-if="mortalityData.length > 0 && totalMortalityData != null && !load">
            <md-table :table-header-color="tableHeaderColor" class="custom-table" style="border: 1px solid black">
                <tr style="background-color: #FFFF96">
                    <th style="border: 0.5px solid black">Total Birds</th>
                    <th style="border: 0.5px solid black">Total Mortality Birds</th>
                    <th style="border: 0.5px solid black">Total Live Birds</th>
                    <th style="border: 0.5px solid black">Total Mortality %</th>
                    <th style="border: 0.5px solid black">Total Mortality Birds Weight</th>
                    <th style="border: 0.5px solid black">Total Livability %</th>
                </tr>
                
                <tr>
                    <td style="border: 0.5px solid black">{{ totalMortalityData.final_total_birds }}</td>
                    <td style="border: 0.5px solid black">{{ totalMortalityData.final_total_mortality }}</td>
                    <td style="border: 0.5px solid black">{{ totalMortalityData.final_total_live_birds }}</td>
                    <td style="border: 0.5px solid black">{{ totalMortalityData.final_mortality_percentage }}</td>
                    <td style="border: 0.5px solid black">{{ totalMortalityData.final_mortal_birds_weight }}</td>
                    <td style="border: 0.5px solid black">{{ totalMortalityData.final_livability_percentage }}</td>
                </tr>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
  
  <script>
import LoadingBar from "../../components/LoadingBar.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

export default {
  components: { LoadingBar },
  name: "MortalDetails",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
        mortalityData: [],  
        totalMortalityData: null,
        activeTab: "list", // Default active tab
        expInfoId: "",
        trialNo: "",
        startDate: "",
        endDate: "",
        mortalBirdsReportList: [],
        load: false,
    };
  },
  watch: {
    // Watch for changes in the selected trial's id
    '$store.getters.getSelectedTrial.id': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // Update related data when id changes
          this.expInfoId = newVal;
          this.startDate = this.$store.getters.getSelectedTrial.start_date;
          this.endDate = this.$store.getters.getSelectedTrial.end_date;
          this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
          // Call the filterData method whenever the trial id changes
          this.filterData();
        }
      },
      immediate: true // Call handler immediately after the component is mounted
    }
  },


  mounted() {
    this.expInfoId = this.$store.getters.getSelectedTrial.id;
    this.trialNo = this.$store.getters.getSelectedTrial.trial_no;
    this.startDate = this.$store.getters.getSelectedTrial.start_date;
    this.endDate = this.$store.getters.getSelectedTrial.end_date;
  },

  // watch: {
  //   trialNoDrp: function (value) {
  //     this.getTrialNoList();
  //   },
  // },

    methods: {
        isActive(tab) {
        return this.activeTab === tab;
        },
        setActiveTab(tab) {
        this.activeTab = tab;
        },

        filterData(){
            this.mortalityData = [];
            this.totalMortalityData = [];
        },

        async getGroupwiseMortalData(){
            this.load = true;
            this.mortalityData = [];
            try {
                const response = await axios.get(`/indous-research/reports/groupwise-mortal-details`);
                this.mortalityData = response.data.groupwiseMortalityData;
                this.totalMortalityData = response.data.totalMortalityData;
                this.load = false;
            } catch (error) {
                this.load = false;
            }
        },

        exportData() {
        axios
            .post(
            "/download-mortal-birds-excel-report",
            {
                reportData: this.mortalBirdsReportList,
            },
            {
                responseType: "blob",
                headers: {
                "Content-Type": "application/json",
                },
            }
            )
            .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "MortalBirdsReport.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            })
            .catch((error) => {
            // console.error("There was an error generating the report:", error);
            });
        },

        formatColumnHeadings(data) {
            const headers = data[0].map(header => header); // Format headers
            const formattedData = [headers, ...data.slice(1)]; // Combine formatted headers with data
            return formattedData;
        },

    
        calculateColumnWidths(data) {
            const columnWidths = data[0].map((header, colIndex) => {
                let maxLength = header.length;
                data.slice(1).forEach(row => {
                    const cellValue = row[colIndex] ? row[colIndex].toString() : '';
                    maxLength = Math.max(maxLength, cellValue.length);
                });
                return { wch: maxLength };
            });
        
            return columnWidths;
        },

        downloadPDF() {
            const doc = new jsPDF();

            // Title
            doc.setFont("helvetica", "bold");
            doc.setFontSize(14);
            const titleText = "GROUPWISE MORTAL BIRD DETAILS";
            const pageWidth = doc.internal.pageSize.width;
            const textWidth = doc.getTextWidth(titleText);
            const centerPosition = (pageWidth - textWidth) / 2;
            doc.text(titleText, centerPosition, 15);

            const trialNo = `Trial No: ${this.trialNo}`;
            const startDate = `Start Date: ${this.startDate}`;
            const endDate = `End Date: ${this.endDate}`;
            doc.setFontSize(10);
            doc.setFont("Helvetica", "bold");
            doc.text(trialNo, 15, 25);
            doc.text(startDate, pageWidth-55, 25);
            doc.text(endDate, pageWidth-55, 30);

            let currentY = 35;

            // Loop through mortalityData to create tables
            this.mortalityData.forEach((data) => {
                const pens = data.penwise_mortality_count.map((p) => p.pen);
                const mortality = data.penwise_mortality_count.map((p) => p.count);

                // Penwise data
                const penwiseTable = [
                    ["Pens", ...pens],
                    ["Mortality", ...mortality],
                ];

                // Summary data (to be spanned)
                const summaryTable = [
                    ["Groupwise Birds", data.total_alloted_birds],
                    ["Mortality Birds", data.total_mortality_count],
                    ["Live Birds", data.live_birds_remaining],
                    ["Mortality Birds Weight", data.total_mortality_weight],
                    ["Mortality %", `${data.mortality_percentage}%`],
                ];

                // Total number of columns for trial group header + pen columns
                const totalColumns = pens.length + 1;

                // Combine Trial Group Header, penwise data, and summary data into one table
                const combinedTable = [
                    // Trial Group ID row (spanning all columns)
                    [`Trial Group: T${data.trial_group_id}`, ...Array(totalColumns - 1).fill('')],
                    ...penwiseTable,
                    ...summaryTable
                ];

                // Add table with column span for Trial Group ID and summary data values
                doc.autoTable({
                    body: combinedTable,
                    startY: currentY,
                    theme: "grid",
                    styles: { cellPadding: 2.5, fontSize: 9.5, textColor: 0},
                    headStyles: { fillColor: [41, 128, 185], textColor: 255 },
                    didParseCell: function (dataCell) {
                        const { row, column, cell } = dataCell;

                        // Apply column span for Trial Group ID row (first row)
                        if (row.index === 0) {
                            cell.colSpan = totalColumns; // Span all columns (Trial Group ID row)
                            cell.styles.fillColor = [41, 128, 185];
                            cell.styles.fontStyle = "bold";
                            cell.styles.halign = "center"; // Center alignment
                            cell.styles.textColor = 255; // White text
                        }

                        // Apply column span for summary values
                        if (row.index >= penwiseTable.length + 1) {
                            const penDataLength = pens.length + 1; // Number of columns for pens
                            if (column.index === 1) {
                                cell.colSpan = penDataLength - 1; // Span remaining columns for value
                                cell.styles.halign = "left"; // Align text to the left
                            } else if (column.index > 1) {
                                cell.colSpan = 0; // Hide additional columns
                            }
                        }
                    },
                    pageBreak: 'avoid'
                });

                // Move below the table
                currentY = doc.lastAutoTable.finalY + 15;
            });

            // Add Total Mortality Data if available
            if (this.mortalityData.length > 0 && this.totalMortalityData) {
                const totalTable = [
                    [
                        "Total Birds",
                        "Total Mortality Birds",
                        "Total Live Birds",
                        "Total Mortality %",
                        "Total Mortality Birds Weight",
                        "Total Livability %",
                    ],
                    [
                        this.totalMortalityData.final_total_birds,
                        this.totalMortalityData.final_total_mortality,
                        this.totalMortalityData.final_total_live_birds,
                        `${this.totalMortalityData.final_mortality_percentage}%`,
                        this.totalMortalityData.final_mortal_birds_weight,
                        `${this.totalMortalityData.final_livability_percentage}%`,
                    ],
                ];

                doc.autoTable({
                    head: [totalTable[0]],
                    body: [totalTable[1]],
                    startY: currentY,
                    theme: "striped",
                    styles: { cellPadding: 3, fontSize: 10, textColor: 0  },
                    headStyles: { fillColor: [41, 128, 185], textColor: 255 },
                    pageBreak: 'avoid'
                });
            }

            // Save the PDF
            doc.save("GroupwiseMortalBirdDetails.pdf");
        }

    }   
};
</script>
  
  <style scoped>
.custom-md-field {
  width: 190px;
}

.custom-md-input {
  width: 50px;
  font-size: 14px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 1 0 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.card-box {
  background-color: #fff;
  padding: 1.2rem 0rem 0.2rem 0rem;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  margin-bottom: 1px;
  border-radius: 0.25rem;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.input-group {
  display: inline;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.create-field {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.all-buttons {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
}

.removePadding{
    padding: 0;
}

/* .addPadding{
    padding-right: 30px;
} */
</style>