<template>
    <div>
        <div class="sticky-top bg-white border-top border-bottom ml-3 mr-3"  style="z-index:1;">
                    <div class="ml-4 mr-4">
                        <div class="d-flex">
                            <div class="ml-4"></div><div class="ml-5"></div><div class="ml-5"></div>
                            <div v-if="!this.preview" class="col-md-2">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="ml-5">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="!this.stored && !this.load" class="pl-4">
                                <md-button class="md-raised md-primary" @click="chk" :disabled="saving">
                                    {{(this.saving) ? 'saving...' : 'save'}}
                                </md-button>
                            </div>

                            <div v-if="this.stored && !this.load" class="pl-4">
                                <md-button class="md-raised md-primary" @click="updateResultSub" :disabled="updating">
                                    {{(this.updating) ? 'updating...' : 'update'}}
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="pl-4">
                                <md-button @click="exportToPDF"  type="button" class="md-success" :disabled="download"><md-icon>download</md-icon>{{download ? 'Downloading...':'Download PDF'}}
                                </md-button>
                            </div>
                        </div>
                    </div>
        </div>
 <div class="d-flex justify-content-center" id="theme">
    <div style="width:69%;z-index:0">
        <md-card>
            <md-card-content>
                <div ref="pdfContent" class="content-to-export mr-2 ml-2">
                <div class="certificate-container">
                <div class="header border border-dark">
                    <h1 class="m-0">INDOUS RESEARCH</h1>
                    <h5 class="m-0">A unit of INDOUS HOLDINGS</h5>
                </div>

                <div class="border border-dark pl-3 pr-3 mt-5">
                <div class="d-flex">
                        <div class="pl-5"></div>
                        <div><h3 class="mt-1 pl-4"><u>BROILER NUTRITION TRIAL REPORT</u></h3></div>
                        <div class="mt-2 pl-5"><h5 class="mt-1 mb-0"><b>{{ this.displayTrialNo }}</b></h5></div>
                                    
                </div>
                <div class="d-flex justify-content-end pr-3 mr-4">
                    <div v-if="!this.preview" style="width: 30%;">
                        <div class="d-flex align-items-center pr-0">
                            <span><b>Date:- </b></span>
                            <md-datepicker v-model="startDate" md-immediately style="margin-top:0px" />
                        </div>
                    </div>
                        <p v-if="this.preview" class="mt-0 mb-0 pr-4" style="font-size:17px;">Date:- {{ startDate }}</p>
                    </div>
                <div>
                    <p class="fs-5">To,</p>
                                <div class="ml-4">
                                    <p 
                                        id="cstmFont11"
                                        style="width: 80%; line-height: 2;" 
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 11 }" 
                                        @focus="onEdit(11)" 
                                        @blur="onBlur"
                                        @input="checkPlaceholder"
                                    >
                                        Dr. P Manohara Upadhya<br>
                                        C/O Vet Pet Formulations<br>
                                        1-22/7 (1)<br>
                                        Near Soorya Narayana Temple<br>
                                        Maroli, Mangalore- 575005<br>
                                        Mob:-9343345603
                                    </p>
                    </div>
                    <p 
                        id="cstmFont2a"
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 1 }" 
                        @focus="onEdit(1)" 
                        @blur="onBlur"
                     >Respected Sir,</p>
                    <p
                        id="cstmFont3a"
                        class="subject" 
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 2 }" 
                        @focus="onEdit(2)" 
                        @blur="onBlur"
                    >Sub:- Submitting the results for the experiment conducted on</p>
                    <p 
                        id="cstmFont4a"
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 3 }"
                        @focus="onEdit(3)"
                        @blur="onBlur"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"To find out zoo technical parameters of Broiler Birds, fed with Product X(FCR formula) ,in Broiler Diets".</p>
                    <p 
                        id="cstmFont5"
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 4 }" 
                        @focus="onEdit(4)" 
                        @blur="onBlur"
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With reference to the above mentioned subject we have conducted a Trial between 14-04-2024 to 09-05-2024. We are submitting the Final Report on the outcome of the experiment, Trial No {{ this.displayTrialNo }}. 
                    This is for your kind information and reference.
                    </p>
            
                    <p
                        id="cstmFont6"
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 6 }" 
                        @focus="onEdit(6)" 
                        @blur="onBlur" 
                    >&nbsp;&nbsp;&nbsp;&nbsp;We are thankful to you for giving us this Opportunity and Sponsoring the Trial Expenses.</p>
            
                    <p
                        id="cstmFont6"
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 7 }" 
                        @focus="onEdit(7)" 
                        @blur="onBlur"
                    >Thanking You</p>
                    <br>
            
                    <p
                        id="cstmFont7"
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 8 }" 
                        @focus="onEdit(8)" 
                        @blur="onBlur"
                    
                    >Your's faithfully</p>
                    <br>
                    <br>
                    <p
                        id="cstmFont8"
                        :contenteditable="contentEdit" 
                        :class="{ 'editing': currentEditing === 9 }" 
                        @focus="onEdit(9)" 
                        @blur="onBlur"><strong>FOR INDOUS RESEARCH</strong></p>
                </div>
                </div>
            </div>
            <div class="border border-dark mt-5"></div>
            <div class="d-flex align-items-start w-100 px-3">
                                    <p 
                                        id="cstmFont9"
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 10 }" 
                                        @focus="onEdit(10)" 
                                        @blur="onBlur"
                                        style="font-size:12px"
                                    >
                                        1st Floor, Annapoorneshwari Building, Mahaveera Circle Pumpwell, Mangalore -575002
                                    </p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>
  
  <script>
 import jsPDF from 'jspdf';
 import html2canvas from 'html2canvas';
  export default {
    data() {
      return {
        placeholderText1: 'Enter Name',
        placeholderText2: 'Enter Address',
        content: '', 
        trialNo: "",
        displayTrialNo: "",
        currDate: "30-11-2022",
        name:'',
        preview:false,
        startDate:'',
        contentEdit:true,
        address1:'',
        address2:'',
        address3:'',
        currentEditing:null,
        download:false,
        stored:false,
        load:true,
        saving:false,
        updating:false,
      };
    },

    mounted(){
        this.$material.locale.dateFormat = "dd-MM-yyyy";
        this.date_function();
        this.getTrialNumber();
    },

    computed: {
        startDateFormatted() {
        if (!this.startDate) return "";
        const [year, month, day] = this.startDate.split("-");
        return `${day}-${month}-${year}`;
        },
    },

    methods:{
        checkPlaceholder(event) {
            const p = event.target;
            if (!p.textContent.trim()) {
                p.setAttribute('data-placeholder', this.placeholderText);
            } else {
                p.removeAttribute('data-placeholder');
            }
        },

        chk() {
            this.saving = true;
            const resultSub = {
        experiment_info_id: this.trialNo,
        details:{
                    start_date: this.startDate,
                    p2: document.getElementById('cstmFont2a')?.innerHTML || '',
                    p3: document.getElementById('cstmFont3a')?.innerHTML || '',
                    p4: document.getElementById('cstmFont4a').innerHTML,
                    p5: document.getElementById('cstmFont5').innerHTML,
                    p6: document.getElementById('cstmFont6').innerHTML,
                    p7: document.getElementById('cstmFont7').innerHTML,
                    p8: document.getElementById('cstmFont8').innerHTML,
                    p9: document.getElementById('cstmFont9').innerHTML,
                    p10: document.getElementById('cstmFont11').innerHTML,
                },
            };
            axios.post('/indous-research/result-submission', resultSub)
                .then(response => {
                    this.getResultSub();
                })
                .catch(error => {
                    this.$notify({ 
                                    type: 'error', 
                                    message: 'Failed to save certificate',
                                    horizontalAlign: 'center',
                                    verticalAlign: 'bottom',
                                    type: 'danger'
                     });
                });
        },

        async getTrialNumber() {
          const temp = await this.$store.getters.getSelectedTrial;
          this.trialNo = temp.id;
          this.displayTrialNo = temp.trial_no;
          this.getResultSub();
        },

        getTrialNoList() {
        axios
            .get(`/indous-research/experiment-information/get-trial-no-list`)
            .then((response) => {
            this.trialNo = this.trialNoList[0]?.trial_no;
            this.startingDate = this.trialNoList[0]?.start_date;
            this.filteredChickCategory();
            this.fetchFeedFormulaList();
            });
        },

        async getResultSub() {
            try {
                const allInfo = await axios.get('/indous-research/get-resultsub-info', {
                    params: {
                        experiment_info_id: this.trialNo
                    }
                });
                this.load=false;
                this.startDate=allInfo.data[0].details.start_date;
                document.getElementById('cstmFont2a').innerHTML =allInfo.data[0].details.p2;
                document.getElementById('cstmFont3a').innerHTML =allInfo.data[0].details.p3;
                document.getElementById('cstmFont4a').innerHTML=allInfo.data[0].details.p4;
                document.getElementById('cstmFont5').innerHTML=allInfo.data[0].details.p5;
                document.getElementById('cstmFont6').innerHTML=allInfo.data[0].details.p6;
                document.getElementById('cstmFont7').innerHTML=allInfo.data[0].details.p7;
                document.getElementById('cstmFont8').innerHTML=allInfo.data[0].details.p8;
                document.getElementById('cstmFont9').innerHTML=allInfo.data[0].details.p9;
                document.getElementById('cstmFont11').innerHTML=allInfo.data[0].details.p10;
                this.stored=true;
                this.saving = false;
            }catch (error) {
            }
        },

        async updateResultSub() {
            try {
                this.updating = true;
                const resSubData = {
                    experiment_info_id:this.trialNo,
                    details:{
                            start_date: this.startDate,
                            p2: document.getElementById('cstmFont2a')?.innerHTML || '',
                            p3: document.getElementById('cstmFont3a')?.innerHTML || '',
                            p4: document.getElementById('cstmFont4a').innerHTML,
                            p5: document.getElementById('cstmFont5').innerHTML,
                            p6: document.getElementById('cstmFont6').innerHTML,
                            p7: document.getElementById('cstmFont7').innerHTML,
                            p8: document.getElementById('cstmFont8').innerHTML,
                            p9: document.getElementById('cstmFont9').innerHTML,
                            p10: document.getElementById('cstmFont11').innerHTML,
                        },
                };

                const response = await axios.put(`/indous-research/update-result-sub`, resSubData);
                this.updating = false;
            } catch (error) {
                this.updating = false;
                this.$notify({ 
                                    type: 'error', 
                                    message: 'Failed to Update certificate',
                                    horizontalAlign: 'center',
                                    verticalAlign: 'bottom',
                                    type: 'danger'
                     });
            }
        },

        onEdit(val){
            this.currentEditing=val;
        },

        onBlur(){
            this.currentEditing=null;
        },

        getTrialNo(){
            axios.get('/indous-research/experiment-information/get-trial-no-list').then(response=>{
            const temp = response.data.trialNoList[0].trial_no;
            this.trialNo = "T" + temp.slice(5);
            })
        },

        date_function(){
            const date = new Date();
            const formattedDate = date.toLocaleDateString('en-GB'); // dd/mm/yyyy format
            const finalDate = formattedDate.replace(/\//g, '-');
            this.startDate=finalDate;
        },

        previewToggle(){
            this.preview = true;
            this.contentEdit = false;
        },

        exportToPDF() {
            this.download=true;
            const pdfContent = this.$refs.pdfContent;
            html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Result_Submission.pdf');
                this.download=false;
            });
        },

        BackFn(){
            this.preview=false;
            this.contentEdit = true;
        },
    }
  };
  </script>
  
  <style scoped>
  p[data-placeholder]:empty::before {
    content: attr(data-placeholder);
    color: gray;
    pointer-events: none; /* Ensures the placeholder isn't selectable */
    display: block; /* Ensures it takes up space */
  }

  .certificate-container {
    font-family: Arial, sans-serif;
    text-align: left;
    width: 100%;
    padding: 20px 25px;
  }
  
  .header {
    text-align: center;
    /* margin-bottom: 20px; */
  }
  
  .header h2 {
    font-size: 24px;
    /* margin-bottom: 10px; */
  }
  
  .header h3 {
    font-size: 20px;
    font-weight: bold;
    /* margin-bottom: 20px; */
  }
  
  .details {
    display: flex;
    justify-content: flex-end;
    margin: 0px 10px 20px 0px;
    font-size: 17px;
  }
  
  .text-right {
    text-align: right;
  }
  
  .content {
    /* margin-top: 20px; */
  }
  
  /* .content p {
    margin: 10px 0;
  } */
  
  .subject {
    /* margin-top: 20px; */
  }
  </style>
  