<template>
    <div>
        <div class="sticky-top bg-white border-top border-bottom ml-3 mr-3" style="z-index:1;">
                    <div class="ml-4 mr-4">
                        <div class="d-flex">
                            <div class="ml-4"></div><div class="ml-5"></div><div class="ml-5"></div>
                            <div v-if="!this.preview" class="col-md-2">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="ml-5">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="!this.stored && !this.load" class="pl-4">
                                <md-button class="md-raised md-primary" @click="chk" :disabled="saving">
                                {{(this.saving) ? 'saving...' : 'save'}}
                                </md-button>
                            </div>

                            <div v-if="this.stored && !this.load" class="pl-4">
                                <md-button class="md-raised md-primary" @click="updateFinalReport" :disabled="updating">
                                {{(this.updating) ? 'updating...' : 'update'}}
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="pl-4">
                                <!-- <md-button class="md-success" @click="exportToPDF">
                                    <md-icon>download</md-icon> Download PDF
                                </md-button> -->
                                <md-button @click="exportToPDF"  type="button" class="md-success" :disabled="download"><md-icon>download</md-icon>{{download ? 'Downloading...':'Download PDF'}}
                                </md-button>
                            </div>
                        </div>
                    </div>
        </div>

        <div class="d-flex justify-content-center" id="theme">
        <div style="width:69%;z-index:0">
        <md-card>
            <md-card-content>
                <div ref="pdfContent" class="content-to-export">
                    <div class="pr-5 pl-5 pt-2">
                    <div class="border border-dark" style="border-style: dotted;">
                            <div class="d-flex flex-column align-items-center pr-0">
                            <h1 class="m-0">INDOUS RESEARCH</h1>
                            <h5 class="m-0">A unit of INDOUS HOLDINGS</h5>
                            <!-- <hr> -->
                            </div>
                        </div>
                        <div class="d-flex justify-content-around">
                            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                            <div><h3 class="mt-1"><u>BROILER NUTRITION TRIALS</u></h3></div>
                            <div class="pt-2">
                                            <h5 class="mt-0"><b>{{ this.displayTrialNo }}</b></h5>
                                            <!-- <select v-if="!this.preview" required v-model="trialNo" id="country" md-dense>
                                                <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.trial_no">
                                                            {{ trial.trial_no }}
                                                </option>
                                            </select> -->
                                            <!-- <input v-if="!this.preview" v-model="trialNumbers[0].trial_no" readonly style="padding:9px;font-size: medium;font-weight: 600;width:15%" /> -->
                                            <!-- <h4><b>{{ this.trialNumbers[0].trial_no }}</b></h4> -->
                                        </div>
                        </div>
                        <div style="border-style: dotted;">
                            <div class="d-flex flex-column align-items-center pr-0 pl-0">
                                <span><h4 class="mt-0"><i><b>Final Report</b></i></h4><br></span>
                                <span><h4 
                                    :contenteditable="contentEdit" 
                                        :class="[ { 'editing': currentEditing === 11 }, 'lh-base' ]" 
                                        @focus="onEdit(11)" 
                                        @blur="onBlur"
                                        id="cstmFont1"
                                    class="mt-0"
                                    ><b>A Research Project</b></h4></span>
                                <span><h4 
                                    :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 12 }" 
                                        @focus="onEdit(12)" 
                                        @blur="onBlur"
                                        id="cstmFont2"
                                    class="mt-0"><b>ON</b></h4><br></span>
                                <div class="w-100 px-2 text-center">
                                    <p 
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 1 }" 
                                        @focus="onEdit(1)" 
                                        @blur="onBlur"
                                        
                                        class="p2 lh-lg" id="para"
                                    >
                                    “TO FINDOUT ZOO TECHNICAL PARAMETERS OF BROILER BIRDS, FED WITH PRODUCT X (FCR FORMULA), IN BROILER DIETS“.
                                    </p>
                                </div>
                                <br>
                                <br>

                                <div class="d-flex align-items-start w-100 px-2">
                                    <div style="width:340%;font-weight:400">
                                        <p
                                            :contenteditable="contentEdit" 
                                            :class="{ 'editing': currentEditing === 8 }" 
                                            @focus="onEdit(8)" 
                                            @blur="onBlur"
                                            style="font-weight:600;"
                                            id="cstmFont4"
                                        ><u>Principal Investigator</u></p>

                                        <!-- <input 
                                                type="text" 
                                                class="form-control rounded mb-2 font-color"
                                                v-model="name"
                                                placeholder="Enter Name Here"
                                                required
                                                style="width: 30%;font-weight:600;"
                                                v-if="!this.preview"
                                            >
                                            <p v-if="this.preview" class="font-color" style="font-weight: 600;" id="place">{{this.name}}</p>
                                        <input 
                                                type="text" 
                                                class="form-control rounded mb-2 font-color"
                                                v-model="address1"
                                                placeholder="Enter Address"
                                                required
                                                style="width: 30%;font-weight:600;"
                                                v-if="!this.preview"
                                            >
                                            <p v-if="this.preview" class="font-color" style="font-weight: 600;" id="place">{{this.address1}}</p>
                                            <input 
                                                type="text" 
                                                class="form-control rounded mb-2 font-color"
                                                v-model="address2"
                                            
                                                required
                                                style="width: 30%;font-weight:600;"
                                                v-if="!this.preview"
                                            > -->
                                <!-- <p v-if="this.preview" class="font-color" style="font-weight: 600;" id="place">{{this.address2}}</p>
                                <input 
                                                type="text" 
                                                class="form-control rounded mb-2 font-color"
                                                v-model="address3"
                                            
                                                required
                                                style="width: 30%;font-weight: 600;"
                                                v-if="!this.preview"
                                            >
                                <p v-if="this.preview" class="font-color" style="font-weight: 600;" id="place">{{this.address3}}</p> -->
                                <strong>
                                <p 
                                        id="cstmFont5"
                                        style="width: 80%; line-height: 2;" 
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 11 }" 
                                        @focus="onEdit(11)" 
                                        @blur="onBlur"
                                        @input="checkPlaceholder"
                                    >
                                        Dr Arun Kumar Rai, B.V.Sc, PGDHRM<br>
                                        Managing Partner<br>
                                        Bharath Agrovet Industries<br>
                                    </p>
                                </strong>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between w-100 px-3">
                                    <div class="p-2"></div>
                                    <div class="p-2"></div>
                                </div>
                        
                                <div class="text-center my-4">
                                    <h4
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 7 }" 
                                        @focus="onEdit(7)" 
                                        @blur="onBlur"
                                        id="cstmFont6"
                                    class="text-decoration-underline"><b>SPONSORED BY</b></h4>
                                    <h3
                                    :contenteditable="contentEdit" 
                                        :class="[{ 'editing': currentEditing === 2 } , 'mt-1' ]" 
                                        @focus="onEdit(2)" 
                                        @blur="onBlur"
                                        id="cstmFont7"
                                    >BHARATH AGROVET INDUSTRIES</h3>
                                </div>

                                <div class="text-center mt-4">
                                    <p
                                    :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 3 }" 
                                        @focus="onEdit(3)" 
                                        @blur="onBlur"
                                        id="cstmFont8"
                                    ><b>IMPLEMENTED BY</b></p>
                                    <p
                                    :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 4 }" 
                                        @focus="onEdit(4)" 
                                        @blur="onBlur"
                                        id="cstmFont9"
                                    ><b>INDOUS RESEARCH</b></p>
                                    <p
                                    :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 5 }" 
                                        @focus="onEdit(5)" 
                                        @blur="onBlur"
                                        id="cstmFont10"
                                    ><b>MANGALORE -575002</b></p>
                                </div>
                            </div>
                        </div>
                </div>

                        <div class="border border-dark mt-5" style="border-style: dotted;"></div>
                        <div class="d-flex align-items-start w-100 px-3">
                                    <p 
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 6 }" 
                                        @focus="onEdit(6)" 
                                        @blur="onBlur"
                                        style="font-size:12px"
                                        id="cstmFont11"
                                    >
                                        1st Floor, Annapoorneshwari Building, Mahaveera Circle Pumpwell, Mangalore -575002
                                    </p>
                        </div>
                    </div>
                <!-- Export to PDF Button -->
            </md-card-content>
        </md-card>
        <!-- <div class="d-flex justify-content-center mt-4">
            <button @click="exportToPDF" class="btn btn-primary">Export to PDF</button>
        </div> -->
        </div>
    </div>

    </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
    data() {
        return {
            trialNo: '',
            currDate: '',
            preview:false,
            contentEdit:true,
            currentEditing:null,
            name:'',
            address1:'',
            address2:'',
            address3:'',
            address4:'',
            download:false,
            displayTrialNo:'',
            stored:false,
            load:true,
            saving:false,
            updating:false
        };
    },

    mounted() {

        this.getTrialNumber();
    },

    methods: {
        onEdit(val){
            this.currentEditing=val;
        },
        onBlur(){
            this.currentEditing=null;
        },

        async updateFinalReport() {
            try {
                this.updating = true;
                // Prepare the data to be sent in the request
                const res = {
                    experiment_info_id:this.trialNo,
                    details:{
                            p1: document.getElementById('cstmFont1')?.innerHTML || '',
                            p2: document.getElementById('cstmFont2')?.innerHTML || '',
                            p3: document.getElementById('para').innerHTML,
                            p4: document.getElementById('cstmFont4').innerHTML,
                            p5: document.getElementById('cstmFont5').innerHTML,
                            p6: document.getElementById('cstmFont6').innerHTML,
                            p7: document.getElementById('cstmFont7').innerHTML,
                            p8: document.getElementById('cstmFont8').innerHTML,
                            p9: document.getElementById('cstmFont9').innerHTML,
                            p10: document.getElementById('cstmFont10').innerHTML,
                            p11: document.getElementById('cstmFont11').innerHTML,
                        },
                };

                // Send the PUT request
                const response = await axios.put(`/indous-research/update-final-report-certificate`, res);
                this.updating=false;
                
            } catch (error) {
                this.updating=false;
                this.$notify({ 
                                    type: 'error', 
                                    message: 'Failed to Update certificate',
                                    horizontalAlign: 'center',
                                    verticalAlign: 'bottom',
                                    type: 'danger'
                     });
            }
        },

        checkPlaceholder(event) {
            const p = event.target;
            if (!p.textContent.trim()) {
                p.setAttribute('data-placeholder', this.placeholderText);
            } else {
                p.removeAttribute('data-placeholder');
            }
        },

        async getTrialNumber() {
          const temp = await this.$store.getters.getSelectedTrial;
          this.trialNo = temp.id;
          this.displayTrialNo = temp.trial_no;
          this.getFinalReportCertifi();
        },

        chk() {
            this.saving=true;
            const report = {
            experiment_info_id: this.trialNo,
            details:{
                        p1: document.getElementById('cstmFont1')?.innerHTML || '',
                        p2: document.getElementById('cstmFont2')?.innerHTML || '',
                        p3: document.getElementById('para').innerHTML,
                        p4: document.getElementById('cstmFont4').innerHTML,
                        p5: document.getElementById('cstmFont5').innerHTML,
                        p6: document.getElementById('cstmFont6').innerHTML,
                        p7: document.getElementById('cstmFont7').innerHTML,
                        p8: document.getElementById('cstmFont8').innerHTML,
                        p9: document.getElementById('cstmFont9').innerHTML,
                        p10: document.getElementById('cstmFont10').innerHTML,
                        p11: document.getElementById('cstmFont11').innerHTML,
                    },
            };
            axios.post('/indous-research/save-final-report-certificate', report)
                .then(response => {
                    this.saving=false;
                    this.getFinalReportCertifi();
                })
                .catch(error => {
                    this.saving=false;
                    this.$notify({ 
                                    type: 'error', 
                                    message: 'Failed to save certificate',
                                    horizontalAlign: 'center',
                                    verticalAlign: 'bottom',
                                    type: 'danger'
                     });
                });
        },

        async getFinalReportCertifi() {
            try {
            const allInfo = await axios.get('/indous-research/get-final-report-certifi-info', {
                params: {
                    experiment_info_id: this.trialNo
                }
            });
            this.load=false;
            this.startDate=allInfo.data[0].details.start_date;
                document.getElementById('cstmFont1').innerHTML =allInfo.data[0].details.p1;
                document.getElementById('cstmFont2').innerHTML =allInfo.data[0].details.p2;
                document.getElementById('para').innerHTML=allInfo.data[0].details.p3;
                document.getElementById('cstmFont4').innerHTML=allInfo.data[0].details.p4;
                document.getElementById('cstmFont5').innerHTML=allInfo.data[0].details.p5;
                document.getElementById('cstmFont6').innerHTML=allInfo.data[0].details.p6;
                document.getElementById('cstmFont7').innerHTML=allInfo.data[0].details.p7;
                document.getElementById('cstmFont8').innerHTML=allInfo.data[0].details.p8;
                document.getElementById('cstmFont9').innerHTML=allInfo.data[0].details.p9;
                document.getElementById('cstmFont10').innerHTML=allInfo.data[0].details.p10;
                document.getElementById('cstmFont11').innerHTML=allInfo.data[0].details.p11;
                this.stored=true;
            }catch (error) {
            }
        },

        BackFn(){
            this.preview=false;
            this.contentEdit=true;
        },

        previewToggle(){
            this.preview=true;
            this.contentEdit=false;
        },

        exportToPDF() {
            this.download=true;
            const pdfContent = this.$refs.pdfContent;
            html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Final_Report.pdf');
                this.download=false;
            });
        }
    }
};
</script>

<style>
.editing {
                border: 2px dashed #007bff; /* Dashed border to indicate editing */
                /* padding: 5px;               Add padding inside the border */
                border-radius: 5px;          /* Make corners rounded */
        }

#theme {
  font-family: Arial, Helvetica, sans-serif;
}

#para {
    font-weight: 600;
    font-size: 20px;
}

.content-to-export {
    padding: 20px;
    background-color: white;
}

p[data-placeholder]:empty::before {
    content: attr(data-placeholder);
    color: gray;
    pointer-events: none; /* Ensures the placeholder isn't selectable */
    display: block; /* Ensures it takes up space */
}

</style>
