<template>
  <div>
    <md-toolbar
      md-elevation="0"
      class="md-transparent mb-4"
      style="background-color: lightgrey !important"
    >
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <h3 class="md-title font-weight-normal text-uppercase">
            {{ $route.name }}
          </h3>
        </div>
        <div class="md-toolbar-section-end">
          <md-button
            class="md-just-icon md-simple md-toolbar-toggle"
            :class="{ toggled: $sidebar.showSidebar }"
            @click="toggleSidebar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>

          <div class="md-collapse">
            <!-- <div class="md-autocomplete">
              <md-autocomplete
                class="search"
                v-model="selectedEmployee"
                :md-options="employees"
              >
                <label>Search...</label>
              </md-autocomplete>
            </div> -->
            <md-list>
              <!-- <md-list-item href="#/">
                <i class="material-icons">dashboard</i>
                <p class="hidden-lg hidden-md">Dashboard</p>
              </md-list-item> -->

              <!-- <md-list-item href="#/notifications" class="dropdown">
                <drop-down>
                  <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p class="hidden-lg hidden-md">Notifications</p>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-right">
                    <li><a href="#">Mike John responded to your email</a></li>
                    <li><a href="#">You have 5 new tasks</a></li>
                    <li><a href="#">You're now friend with Andrew</a></li>
                    <li><a href="#">Another Notification</a></li>
                    <li><a href="#">Another One</a></li>
                  </ul>
                </drop-down>
              </md-list-item> -->

              <!-- <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down>
                      <md-button
                        slot="title"
                        class="md-button md-just-icon md-simple"
                        data-toggle="dropdown"
                      >
                        <md-icon>notifications</md-icon>
                        <span class="notification">3</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li><a href="#">Notification 1</a></li>
                        <li><a href="#">Notification 2</a></li>
                        <li><a href="#">Notification 3</a></li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li> -->

              <!-- <md-list-item href="#/user">
                <i class="material-icons">person</i>
                <p class="hidden-lg hidden-md">Profile</p>
              </md-list-item> -->
              <!-- <drop-down>
                      <md-button
                        slot="title"
                        class="md-button md-just-icon md-simple"
                        data-toggle="dropdown"
                      >
                        <md-icon>person</md-icon>
                       
                        <p class="hidden-lg hidden-md">Profile</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right py-0">
                      
                        <li class="p-0"><a class="d-block w-100 m-0 py-1" href="javascript:void(0)"  @click="logout">Logout</a></li>
                      </ul>
                     
              </drop-down> -->
              <!-- <div class="my-auto"> {{name}}</div> -->

              <div
                class="dropdown-container"
                ref="dropdown"
              >
                <div class="dropdown">
                  <button class="dropdown-button" @click="toggleDropdown">
                    <span>
                      <i class="fas fa-calendar"></i>
                      {{
                        selectedTrial
                          ? selectedTrial.trial_no
                          : "Select Trial No"
                      }}
                    </span>
                    <i v-if="isDropdownAllowed" class="fas fa-chevron-down"></i>
                  </button>

                  <div
                    v-show="isOpen && isDropdownAllowed"
                    class="dropdown-content"
                  >
                    <ul class="years-list">
                      <li
                        v-for="(year, index) in years"
                        :key="year"
                        class="year-item"
                        :class="{ active: clickedYear === year }"
                        @click="clickYear(year)"
                      >
                        <i class="fas fa-calendar-year"></i>
                        <span class="year-label">
                          {{ year }}
                          <i
                            v-if="clickedYear !== year"
                            class="fas fa-chevron-right"
                          ></i>
                          <i v-else class="fas fa-chevron-down"></i>
                        </span>
                        <div
                          v-show="clickedYear === year"
                          class="trials-list-container"
                          :style="{ top: `${index * 40}px` }"
                        >
                          <ul class="trials-list">
                            <li
                              v-for="trial in getTrialsByYear(year)"
                              :key="trial.id"
                              @click="selectTrial(trial)"
                            >
                              <i class="fas fa-vial"></i> {{ trial.trial_no }}
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="btn-group">
                <div
                  class="btn dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <md-icon class="text-secondary mr-1">person</md-icon>
                  <span class="text-secondary mb-0">{{ user.name }}</span>
                </div>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#resetPass"
                    >Reset password</a
                  >
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    @click="logout"
                    >Logout</a
                  >
                </div>
              </div>
            </md-list>
          </div>
        </div>
      </div>
    </md-toolbar>
    <reset-password :user="user" id="resetPass"></reset-password>
  </div>
</template>
  
  <script>
import ResetPassword from "../../components/ResetPassword.vue";
import { mapState } from "vuex";
export default {
  components: { ResetPassword },
  data() {
    return {
      trialNoList: [],
      selectedEmployee: null,
      isOpen: false,
      hoveredYear: null,
      clickedYear: null,
      employees: [
        "Jim Halpert",
        "Dwight Schrute",
        "Michael Scott",
        "Pam Beesly",
        "Angela Martin",
        "Kelly Kapoor",
        "Ryan Howard",
        "Kevin Malone",
      ],

      user: "",
    };
  },
  computed: {
  isDropdownAllowed() {
    return this.$route.path === "/dashboard" || this.$route.path === "/penwise-weekly-report" || this.$route.path === "/weight-5thweek" || this.$route.path === "/mortal-details" || this.$route.path === "/bird-ratio" || this.$route.path === "/groupwise-mortal-details" || this.$route.path === "/penwise-daily-feed-consumption" || this.$route.path === "/groupwise-weight" || this.$route.path === "/final-summary-report";
  },
    ...mapState({
      selectedTrial: (state) => state.selectedTrial,
    }),
    years() {
      return [...new Set(this.trialNoList.map((trial) => trial.year))];
    },
    formattedOptions() {
      const formatted = [];
      this.years.forEach((year) => {
        formatted.push({
          year,
          trials: this.getTrialsByYear(year),
        });
      });
      return formatted;
    },
    options() {
      return this.formattedOptions.flatMap((item) =>
        item.trials.map((trial) => ({
          ...trial,
          year: item.year,
        }))
      );
    },
  },
  created() {
    this.$store.dispatch("loadSelectedTrial");
  },
  mounted() {
    this.user = this.$store.getters.getUser;
    this.getTrialNoList();
    document.addEventListener("click", this.handleClickOutside);
    this.$root.$on("trialCreated", () => {
      this.getTrialNoList(() => {
        if (this.trialNoList.length > 0) {
          const firstTrial = this.trialNoList[0];
          this.selectTrial(firstTrial);
        }
      });
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    this.$root.$off("trialCreated");
  },
  methods: {
    isDropdownDisplay() {
      const hiddenRoutes = [
       
      ];
      // console.log(hiddenRoutes.includes(this.$route.path));
      return !hiddenRoutes.includes(this.$route.path);
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
        this.clickedYear = null;
      }
    },
    clickYear(year) {
      this.clickedYear = this.clickedYear === year ? null : year;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.clickedYear = null;
    },
    hoverYear(year) {
      this.hoveredYear = year;
    },
    clearHoveredYear() {
      this.hoveredYear = null;
    },
    getTrialsByYear(year) {
      return this.trialNoList.filter((trial) => trial.year === year);
    },
    selectTrial(trial) {
      this.isOpen = false;
      this.$store.commit("setSelectedTrial", trial);
      // console.log(this.$store.getters.getSelectedTrial);
    },

    getTrialNoList(callback) {
      axios.get(`/indous-research/reports/get-trial-list`).then((response) => {
        this.trialNoList = response.data.trialNoList;
        const selectedTrial = this.$store.getters.getSelectedTrial;
        if (this.trialNoList.length > 0) {
          if (!selectedTrial || !selectedTrial.id) {
            const firstTrial = this.trialNoList[0]; 
            this.selectTrial(firstTrial); 
          } else {
            const foundTrial = this.trialNoList.find(
              (trial) => trial.id === selectedTrial.id
            );
            if (!foundTrial) {
              const firstTrial = this.trialNoList[0];
              this.selectTrial(firstTrial);
            }
          }
        }
        if (typeof callback === "function") {
          callback();
        }
      });
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    logout() {
      axios
        .post("/logout")
        .then((response) => {
          this.$store.commit("clearAuth");
          this.$store.commit("setSubMenus", null);
          this.$router.push(`/login`);
        })
        .catch((err) => {
          // console.log(err.response.data.error)
        });
    },
  },
};
</script>
  
  <style scoped>
.navbar-list {
  display: flex;
  align-items: center;
}

.md-list-item {
  margin-left: 10px;
}

.md-list-item .dropdown-menu {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  padding: 8px 0;
}

.md-list-item .dropdown-menu a {
  padding: 10px 15px;
  color: #0b0a0a;
  text-decoration: none;
  display: block;
  transition: background-color 0.2s ease;
}

.md-list-item .dropdown-menu a:hover {
  background-color: #f7f7f7;
}

.experiment-dropdown {
  display: flex;
  align-items: center;
}

.md-button.md-dropdown-btn {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background-color: #e0e0e0;
  border-radius: 4px;
}
c
.experiment-text {
  margin-left: 8px;
  font-size: 14px;
}

.dropdown-container {
  position: relative;
  right: 30%;
  display: inline-block;
  font-family: Arial, sans-serif;
  max-width: 300px; /* Restrict overall width */
}

/* Dropdown Button Styling */
.dropdown-button {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 150px; /* Smaller width */
  font-size: 14px;
  transition: all 0.3s ease;
}

.dropdown-button:hover {
  background-color: #eaeaea;
}

/* Dropdown Content */
.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Years List and Year Item */
.years-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.year-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the year */
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.year-item i {
  margin-right: 10px;
}

.year-item:hover,
.year-item.active {
  background-color: #f1f1f1;
  color: #343e6d;
}

.year-item.active {
  font-weight: bold;
  border-radius: 5px;
}

.year-label {
  margin-left: 5px;
}

/* Trials List Container */
.trials-list-container {
  position: absolute;
  /* top: 0; */
  left: 90%; /* Position the list to the right */
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 2;
  min-width: 110px;
  max-height: 150px; /* Adjust height */
  overflow-y: auto; /* Scrollable */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Trials List */
.trials-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.trials-list li {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 13px;
  transition: background-color 0.3s ease;
}

.trials-list li:hover {
  background-color: #ddd;
}

.trials-list li i {
  margin-right: 8px;
  color: #eeeeee;
}

/* Scrollbar Customization */
.trials-list-container::-webkit-scrollbar {
  width: 6px;
}

.trials-list-container::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.trials-list-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

/* Adjust Input Box */
.dropdown-button {
  border-radius: 8px; /* Rounder corners */
}
</style>
  