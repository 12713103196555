<template>
    <div>
        <div class="sticky-top bg-white border-top border-bottom ml-3 mr-3" style="z-index:1;">
                    <div class="ml-4 mr-4">
                        <div class="d-flex">
                            <div class="ml-4"></div><div class="ml-5"></div><div class="ml-5"></div>
                            <div v-if="!this.preview" class="col-md-2">
                                <md-button class="md-success" @click="previewToggle()">
                                    <md-icon>remove_red_eye</md-icon> Preview
                                </md-button>
                            </div>

                            <div v-if="this.preview" class="ml-5">
                                <md-button class="md-info" @click="BackFn">
                                Back
                                </md-button>
                            </div>

                            <div v-if="!this.stored && !this.load" class="pl-4">
                                <md-button class="md-raised md-primary" @click="chk" :disabled="saving">
                                {{(this.saving) ? 'saving...' : 'save'}}
                                </md-button>
                            </div>
                            <div v-if="this.stored && !this.load" class="pl-4">
                                <md-button class="md-raised md-primary" @click="updateCertificate" :disabled="updating">
                                {{(this.updating) ? 'updating...' : 'update'}}
                                </md-button>
                            </div>
                            

                            <div v-if="this.preview" class="pl-4">
                                <!-- <md-button class="md-success" @click="exportToPDF">
                                    <md-icon>download</md-icon> Download PDF
                                </md-button> -->
                                <md-button @click="exportToPDF"  type="button" class="md-success" :disabled="download"><md-icon>download</md-icon>{{download ? 'Downloading...':'Download PDF'}}
                                </md-button>
                            </div>
                        </div>
                    </div>
        </div>
        
        <!-- <loading-bar v-if="load"></loading-bar> -->
        <div class="d-flex justify-content-center" id="theme">
            <div style="width:69%;z-index:0">
                <md-card>
                    <md-card-content>
                        <div ref="pdfContent" class="content-to-export">
                            <div class="p-3">
                            <div class="d-flex flex-column align-items-center p-0 border border-dark">
                                    <h1 class="m-0">INDOUS RESEARCH</h1>
                                    <h5 class="m-0">A unit of INDOUS HOLDINGS</h5>
                            </div>
                            <hr style="width: 100%; margin: 0px 0px;">
                            <div class="border-custom pl-3 pr-3 pt-4">
                                <div class="d-flex flex-column align-items-center pl-3 pr-3">
                                    <div class="d-flex justify-content-between w-100 ">
                                        <div class="pt-3">
                                            <h4><b>{{ this.displayTrialNo }}</b></h4>
                                            <!-- <loading-bar v-if="load" class="ml-4"></loading-bar>
                                            <div v-else> -->

                                            <!-- <select v-if="!this.preview" required v-model="trialNo" id="country" style="padding:9px;font-size: medium;font-weight: 600;" md-dense>
                                                <option v-for="trial in trialNumbers" :key="trial.id" :value="trial.trial_no">
                                                            {{ trial.trial_no }}
                                                </option>
                                            </select> -->
                                            <!-- <input v-if="!this.preview" v-model="trialNumbers[0].trial_no" readonly style="padding:9px;font-size: medium;font-weight: 600;width:15%" /> -->

                                            <!-- </div> -->
                                                <!-- <p >{{ trialNo }}</p> -->
                                        </div>
                                        <div>
                                            <span class="text-decoration-underline"><h3><b style="display: inline; border-bottom: 3px solid black;">Certificate</b></h3></span>
                                        </div>
                                        <div>
                                            <div v-if="!this.preview" style="width: 140px;">
                                                <md-datepicker v-model="startDate" md-immediately style="margin-top:0px;font-weight:600"/>
                                            </div>
                                            <h4 v-if="this.preview" class="pt-3"><b>{{ startDate }}</b></h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <h3 class="text-decoration-underline"><p 
                                        id="cstmFont11" 
                                        :contenteditable="contentEdit" 
                                        :class="[ { 'editing': currentEditing === 12 }, 'lh-base' ]"
                                        @focus="onEdit(12)" 
                                        @blur="onBlur"
                                        style="display: inline; border-bottom: 2px solid black;" 
                                    >
                                        This is to certify that,  A RESEARCH PROJECT
                                    </p></h3>
                                    <h5>
                                        <p 
                                            id="cstmFont1" 
                                            :contenteditable="contentEdit" 
                                            :class="[ { 'editing': currentEditing === 1 }, 'lh-base' ]"
                                            @focus="onEdit(1)" 
                                            @blur="onBlur" 
                                        >
                                        “TO FINDOUT ZOO TECHNICAL PARAMETERS OF BROILER BIRDS, FED WITH PRODUCT X (FCR FORMULA), IN BROILER DIETS“.
                                        </p>
                                    </h5>
                                </div>

                                <div class="text-left my-4 pl-3 fs-2" id="cstmFont2">
                                    <p 
                                        id="cstmFont2a"
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 2 }" 
                                        @focus="onEdit(2)" 
                                        @blur="onBlur"
                                        @input="paragraph2"
                                        >
                                        Sponsored by <b>VET PER FORMULATIONS, MAROLI, MANGALORE</b></p><br>
                                    <p
                                        id="cstmFont3a"
                                        :contenteditable="contentEdit" 
                                        :class="[{ 'editing': currentEditing === 3}, 'lh-base' ]"
                                        @focus="onEdit(3)" 
                                        @blur="onBlur"
                                        style="margin-top: 26px;line-height: 1.4;" 
                                        >
                                        Was carried out at Research and Development Trial Farm at<br>
                                    <b>INDOUS RESEARCH</b>, Jyothigudde, Mangalore 574143</p>
                                </div>

                                <div class="pb-5">
                                </div>

                                <div class="pb-5">
                                </div>

                            <div class="d-flex align-items-end flex-column pr-5">
                                <div>
                                    <div class="text-left">
                                        <!-- <div> -->
                                            <!-- <span class="mt-3"></span> -->
                                            <!-- <input 
                                                type="text" 
                                                class="form-control rounded mb-2 font-color ml-2" 
                                                v-model="name"
                                                required
                                                style="width: 90%;"
                                                v-if="!this.preview"
                                                id="cstmFont4a"
                                            > -->
                                            <p class="pb-3"
                                                :contenteditable="contentEdit" 
                                                :class="{ 'editing': currentEditing === 4 }" 
                                                @focus="onEdit(4)" 
                                                @blur="onBlur"
                                                id="cstmFont4a"
                                            ><b>Dr Arun Kumar Rai</b></p>
                                        <!-- </div> -->
                                    <!-- <p v-if="this.preview" class="font-color pb-2" id="place"><b>Dr {{this.name}}</b></p> -->
                                    <!-- <p><b>Dr Arun Kumar Rai</b></p> -->
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 5 }" 
                                        @focus="onEdit(5)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                        id="cstmFont4"
                                    ><b>Principal Investigator</b></p>
                                    <p class="pb-3"
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 6 }" 
                                        @focus="onEdit(6)" 
                                        @blur="onBlur"
                                        id="cstmFont5"
                                    ><b>Indous Research</b></p>
                                    </div>
                                    <div class="text-left">
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 7 }" 
                                        @focus="onEdit(7)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                        id="cstmFont6"
                                    ><b>Managing Partner</b></p>
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 8 }" 
                                        @focus="onEdit(8)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                        id="cstmFont7"
                                    >Bharath Agrovet Industries</p>
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 9 }" 
                                        @focus="onEdit(9)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                        id="cstmFont8"
                                    >Mahaveera Circle, Pumpwell</p>
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 10 }" 
                                        @focus="onEdit(10)" 
                                        @blur="onBlur"
                                        style="margin: 0 0 5px;"
                                        id="cstmFont9"
                                    >Mangalore 575002, Karnataka</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        <div class="border border-dark mt-5"></div>
                        <div class="d-flex align-items-start w-100 px-3">
                                    <p
                                        :contenteditable="contentEdit" 
                                        :class="{ 'editing': currentEditing === 11 }" 
                                        @focus="onEdit(11)" 
                                        @blur="onBlur"
                                        style="font-size:12px"
                                        id="cstmFont10"
                                    >
                                        1st Floor, Annapoorneshwari Building, Mahaveera Circle Pumpwell, Mangalore -575002
                                    </p>
                        </div>
                    </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
  </template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LoadingBar from "../../components/LoadingBar.vue";

export default{
    data(){
        return {
            trialNo:'',
            displayTrialNo:'',
            trialNumbers:[],
            currDate:'',
            preview:false,
            startDate:'',
            name:'',
            contentEdit:true,
            isEditing: false,
            currentEditing: null,
            download:false,
            paragraph1:"“ A COMPARATIVE STUDY TO EVALUATE THE CALCIUM AND \nPHOSPHORUS REQUIREMENT ON THE PERFORMANCE OF\n COMMERCIAL BROILERS AND ITS ECONOMICS “",
            paragraph2:"",
            paragraph3:"",
            info1:"",
            info2:"",
            info3:"",
            info4:"",
            info5:"",
            info6:"",
            info7:"",
            ii:`<b>“ A COMPARATIVE STUDY TO EVALUATE YOOOO CALCIUM AND <br>PHOSPHORUS REQUIREMENT ON THE PERFORMANCE OF<br> COMMERCIAL BROILERS AND ITS ECONOMICS “</b>`,
            stored:false,
            load:true,
            saving:false,
            updating:false
        }
    },

    created(){
        // this.date_function();
    },

    mounted(){
        this.$material.locale.dateFormat = "dd-MM-yyyy";
        this.date_function();
        this.getTrialNumber();        
    },

    watch: {
        trialNumbers: {
        immediate: true,
        handler(newList) {
          // if (newList.length > 0) {
            this.trialNo = newList.id;
            this.displayTrialNo = newList.trial_no;
          // }
        }
      },
        startDate(newVal, oldVal){
            
        }
    },

    methods:{
        fnparagraph1(event){
            this.paragraph1 = event.target.textContent;
        },

        async getExpCertificate() {
            try {
            const allInfo = await axios.get('/indous-research/get-certificate-info', {
                params: {
                    experiment_info_id: this.trialNo
                }
            });
            this.load=false;
            JSON.stringify(allInfo.data);
            this.startDate=allInfo.data[0].details.start_date;
                document.getElementById('cstmFont1').innerHTML=allInfo.data[0].details.p1;
                document.getElementById('cstmFont2a').innerHTML =allInfo.data[0].details.p2;
                document.getElementById('cstmFont3a').innerHTML =allInfo.data[0].details.p3;
                document.getElementById('cstmFont4a').innerHTML=allInfo.data[0].details.p4;
                document.getElementById('cstmFont4').innerHTML=allInfo.data[0].details.p5;
                document.getElementById('cstmFont5').innerHTML=allInfo.data[0].details.p6;
                document.getElementById('cstmFont6').innerHTML=allInfo.data[0].details.p7;
                document.getElementById('cstmFont7').innerHTML=allInfo.data[0].details.p8;
                document.getElementById('cstmFont8').innerHTML=allInfo.data[0].details.p9;
                document.getElementById('cstmFont9').innerHTML=allInfo.data[0].details.p10;
                document.getElementById('cstmFont10').innerHTML=allInfo.data[0].details.p11;
                document.getElementById('cstmFont11').innerHTML=allInfo.data[0].details.p12;
                this.stored=true;
                this.saving=false;
            } catch (error) {
            }
        },

        async updateCertificate() {
            try {
                this.updating=true;
                // Prepare the data to be sent in the request
                const certificateData = {
                    experiment_info_id:this.trialNo,
                    details:{
                            start_date: this.startDate,
                            p1: document.getElementById('cstmFont1').innerHTML,
                            p2: document.getElementById('cstmFont2a')?.innerHTML || '',
                            p3: document.getElementById('cstmFont3a')?.innerHTML || '',
                            p4: document.getElementById('cstmFont4a').innerHTML,
                            p5: document.getElementById('cstmFont4').innerHTML,
                            p6: document.getElementById('cstmFont5').innerHTML,
                            p7: document.getElementById('cstmFont6').innerHTML,
                            p8: document.getElementById('cstmFont7').innerHTML,
                            p9: document.getElementById('cstmFont8').innerHTML,
                            p10: document.getElementById('cstmFont9').innerHTML,
                            p11: document.getElementById('cstmFont10').innerHTML,
                            p12: document.getElementById('cstmFont11').innerHTML
                        },
                };

                // Send the PUT request
                const response = await axios.put(`/indous-research/update-certificates`, certificateData);
                this.updating=false;
            } catch (error) {
                this.updating=false;
                this.$notify({ 
                                    type: 'error', 
                                    message: 'Failed to Update certificate',
                                    horizontalAlign: 'center',
                                    verticalAlign: 'bottom',
                                    type: 'danger'
                     });
            }
        },

        async getTrialNumber() {
          const temp = await this.$store.getters.getSelectedTrial;
          this.trialNo = temp.id;
          this.displayTrialNo = temp.trial_no;
          this.getExpCertificate();
        },

        chk() {
            this.saving = true;
            const certify = {
                experiment_info_id: this.trialNo,
                details:{
                            start_date: this.startDate,
                            p1: document.getElementById('cstmFont1').innerHTML,
                            p2: document.getElementById('cstmFont2a')?.innerHTML || '',
                            p3: document.getElementById('cstmFont3a')?.innerHTML || '',
                            p4: document.getElementById('cstmFont4a').innerHTML,
                            p5: document.getElementById('cstmFont4').innerHTML,
                            p6: document.getElementById('cstmFont5').innerHTML,
                            p7: document.getElementById('cstmFont6').innerHTML,
                            p8: document.getElementById('cstmFont7').innerHTML,
                            p9: document.getElementById('cstmFont8').innerHTML,
                            p10: document.getElementById('cstmFont9').innerHTML,
                            p11: document.getElementById('cstmFont10').innerHTML,
                            p12: document.getElementById('cstmFont11').innerHTML
                        },
            };
            axios.post('/indous-research/certificates', certify)
                .then(response => {
                    this.$notify({ type: 'success', message: response.data.message });
                    this.getExpCertificate();
                })
                .catch(error => {
                    this.saving=false;
                    this.$notify({ 
                                    type: 'error', 
                                    message: 'Failed to Save certificate',
                                    horizontalAlign: 'center',
                                    verticalAlign: 'bottom',
                                    type: 'danger'
                     });
                });
        },

        onEdit(val) {
            this.currentEditing = val; // Enable editing state when focused
        },

        onBlur() {
            this.currentEditing = null;  // Disable editing state when focus is lost
        },

        exportToPDF() {
            this.download=true;
            const pdfContent = this.$refs.pdfContent;
            html2canvas(pdfContent, { scale: 2 }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('Certificate.pdf');
                this.download=false;
            });
        },

        BackFn(){
            this.preview=false;
            // let [day, month, year] = this.currDate.split('-');

            // // Rearrange into 'dd-MM-yyyy' format
            // this.currDate = `${year}-${month}-${day}`;
            // console.log("bk",this.currDate);
            this.contentEdit=true;
        },

        getTrialNo(){
            axios.get('/indous-research/experiment-information/get-trial-no-list').then(response=>{
            const temp = response.data.trialNoList[0].trial_no;
            this.displayTrialNo = "T" + temp.slice(5);
            })
        },

        date_function(){
            // console.log("hii");
            const date = new Date();
            const formattedDate = date.toLocaleDateString('en-GB'); // dd/mm/yyyy format
            const finalDate = formattedDate.replace(/\//g, '-');
            // console.log("ddf",finalDate);
            this.startDate=finalDate;
            // console.log("df",this.startDate);
        },

        previewToggle(){
            this.preview=true;
            // let [year, month, day] = this.currDate.split('-');

            // // Rearrange into 'dd-MM-yyyy' format
            // this.currDate = `${day}-${month}-${year}`;
            // console.log("pr",this.currDate);
            this.contentEdit=false;
        }
        
    },

    watch: {
        
        // trialNumbers: {
        //     // immediate: true,
        //     // handler(newList) {
        //     //   if (newList.length > 0) {
        //     //     this.trialNo = newList[0].trial_no;
        //     //   }
        //     // }
        //   },

        trialNo(newVal, oldVal) {
            
        }
    }
}

</script>

<style>
.editing {
                border: 2px dashed #007bff; /* Dashed border to indicate editing */
                /* padding: 5px;               Add padding inside the border */
                border-radius: 5px;          /* Make corners rounded */
        }


.border-custom {
            border: 2px solid black;
            width: 100%;
            margin: 0;
        }

hr {
          border: none;
          height: 2px;
          background-color: 2px solid #0000;
          margin-top: 40px;
      }

#cstmFont1 {
    font-size: 21px;
}

#cstmFont2 {
    font-size: 21px;
}
</style>